<div class="content-wrapper">
    <ng-template #content>
        <div id="modal">
            <div class="modal-header font-weight-bold">
                <h4 class="modal-title font-weight-bold" *ngIf="selectedStudentData">Update Status</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="quickLeadAdd">Add Quick Lead</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="bulkUploadAdd">Student Bulk Upload</h4>
                <h4 class="modal-title font-weight-bold" *ngIf="assignStudentData">
                    Assign Lead to
                    <span *ngIf="user && user['user_type'] === 'SUPER_ADMIN'"> Admin</span>
                    <span *ngIf="user && user['user_type'] === 'ADMIN'"> Head Counsellor</span>
                    <span *ngIf="user && user['user_type'] === 'HEAD_COUNSELLOR'"> Counsellor</span>
                </h4>
                <h4 class="modal-title font-weight-bold text-capitalize" *ngIf="transactionData">Transaction Details
                    - {{transactionData?.name.toLowerCase()}}</h4>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="modalService.dismissAll('')"
                >
                    ×
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="selectedStudentData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <form class="form mt-2 shadow-none" novalidate [formGroup]="updateStatusForm">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="stage_id">Stage</label>
                                            <select class="form-control" id="stage_id" formControlName="stage_id"
                                                    (change)="getReason($event.target.value)">
                                                <option value="">Select Stage</option>
                                                <option [value]="stage.id"
                                                        *ngFor="let stage of stages">{{stage?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && updateStatusForm.get('stage_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="reason_id">Reason</label>
                                            <select class="form-control" id="reason_id" formControlName="reason_id">
                                                <option value="">Select Reason</option>
                                                <option [value]="reason.id"
                                                        *ngFor="let reason of reasons">{{reason?.reason?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && updateStatusForm.get('reason_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="remark">Remark</label>
                                            <textarea class="form-control resize-none" [rows]="5" id="remark"
                                                      formControlName="remark"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" *ngIf="quickLeadAdd">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body mt-1">
                            <form class="form" novalidate [formGroup]="addQuickLeadForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="university_id"
                                                    (change)="getUniversityCourses($event.target.value)">
                                                <option value="">Select University</option>
                                                <option [value]="university.university.id"
                                                        *ngFor="let university of universities">{{university?.university?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addQuickLeadForm.get('university_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="text" class="form-control" placeholder="Name"
                                                   formControlName="name"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('name').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="email" class="form-control" placeholder="Email"
                                                   formControlName="email"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('email').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && addQuickLeadForm.controls['email'].errors && addQuickLeadForm.controls['email'].errors.pattern"
                                                   class="text-danger">Invalid email format</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <input type="number" class="form-control" placeholder="Contact"
                                                   formControlName="contact"/>
                                            <small *ngIf="submitted && addQuickLeadForm.get('contact').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && addQuickLeadForm.controls['contact'].errors && addQuickLeadForm.controls['contact'].errors.pattern"
                                                   class="text-danger">Invalid contact format</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <select class="form-control" formControlName="course_id">
                                                <option value="">Choose Course</option>
                                                <option [value]="course?.id"
                                                        *ngFor="let course of courses">{{course?.name}}</option>
                                            </select>
                                            <small *ngIf="submitted && addQuickLeadForm.get('course_id').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="assignStudentData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body">
                            <div class="row mt-1">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center shadow-complete">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">#</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <ng-container
                                                *ngIf="(admins && admins.length > 0) || (headCounsellors && headCounsellors.length > 0) || (counsellors && counsellors.length > 0); else noAssignDataToShowWeb">
                                            <tbody *ngIf="admins && admins.length > 0 && user && user['user_type'] && user['user_type'] === 'SUPER_ADMIN'">
                                            <tr *ngFor="let admin of admins; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(admin, 'ADMIN', i, true)"
                                                       *ngIf="admin && !admin['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(admin, 'ADMIN', i, false)"
                                                       *ngIf="admin && admin['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{admin?.name}}</td>
                                                <td class="text-center">{{admin?.username}}</td>
                                                <td class="text-center">{{admin?.email}}</td>
                                                <td class="text-center">{{admin?.contact}}</td>
                                            </tr>
                                            </tbody>
                                            <tbody *ngIf="headCounsellors && headCounsellors.length > 0 && user && user['user_type'] && user['user_type'] === 'ADMIN'">
                                            <tr *ngFor="let headCounsellor of headCounsellors; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(headCounsellor, 'HEAD_COUNSELLOR', i, true)"
                                                       *ngIf="headCounsellor && !headCounsellor['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(headCounsellor, 'HEAD_COUNSELLOR', i, false)"
                                                       *ngIf="headCounsellor && headCounsellor['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{headCounsellor?.user?.name}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.username}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.email}}</td>
                                                <td class="text-center">{{headCounsellor?.user?.contact}}</td>
                                            </tr>
                                            </tbody>
                                            <tbody *ngIf="counsellors && counsellors.length > 0 && user && user['user_type'] && user['user_type'] === 'HEAD_COUNSELLOR'">
                                            <tr *ngFor="let counsellor of counsellors; let i = index">
                                                <td class="text-center">
                                                    <i class="far fa-check-circle cursor-pointer"
                                                       (click)="chooseAssignable(counsellor, 'COUNSELLOR', i, true)"
                                                       *ngIf="counsellor && !counsellor['assignable']"></i>
                                                    <i class="far fa-check-circle cursor-pointer text-success"
                                                       (click)="chooseAssignable(counsellor, 'COUNSELLOR', i, false)"
                                                       *ngIf="counsellor && counsellor['assignable']"></i>
                                                </td>
                                                <td class="text-center">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .
                                                </td>
                                                <td class="text-center">{{counsellor['counsellor'].name}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].username}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].email}}</td>
                                                <td class="text-center">{{counsellor['counsellor'].contact}}</td>
                                            </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #noAssignDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="6">No data to show</td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                            <div class="row pagination-table">
                                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                                [(page)]="pagination.page" [boundaryLinks]="true"
                                                [pageSize]="pagination.perpage" [maxSize]="5"
                                                (pageChange)="onAssignPageChange($event)">
                                    <ng-template ngbPaginationPrevious>
                                        <span [data-feather]="'chevron-left'" [size]="18"
                                              [class]="'font-weight-bolder'">
                                        </span>
                                    </ng-template>
                                    <ng-template ngbPaginationNext>
                                        <span [data-feather]="'chevron-right'" [size]="18"
                                              [class]="'font-weight-bolder'">
                                        </span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row mt--5px mb--5px">
                                <div class="col-12">
                                    <button class="btn btn-success pull-right" (click)="assignToStudent()">Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="bulkUploadAdd">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         *ngIf="bulkStudentData.length > 0 && this.universities.length > 1">
                        <div class="row">
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12"></div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <form class="form" novalidate [formGroup]="bulkUniversityForm">
                                    <select class="form-control" formControlName="university_id">
                                        <option value="">Select University</option>
                                        <option [value]="university.university.id"
                                                *ngFor="let university of universities">{{university?.university?.name}}</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card-body text-center" *ngIf="showFetchingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, updating students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center" *ngIf="showUpdatingDataLoader">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <i class="fas fa-spinner fa-pulse p-5 text-primary fa-3x"></i>
                                </div>
                                <div class="col-12 text-center text-primary">
                                    Please wait, fetching students record ...
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center"
                             *ngIf="!bulkStudentData || bulkStudentData.length === 0 && !showFetchingDataLoader">
                            <i class="fas fa-file-excel fa-5x p-100px text-success cursor-pointer"
                               (click)="fileUploadBulk.click()"></i>
                            <input class="form-control-file display-hidden" type="file"
                                   (change)="onFileChange($event, 'STUDENT_BULK_UPLOAD')" #fileUploadBulk>
                            <h6>Upload New Excel (supported format xls, xlsx)</h6>
                        </div>
                        <div class="card-body mt-1 text-center overflow-x-unset overflow-y-scroll max-height-700px"
                             *ngIf="bulkStudentData && bulkStudentData.length > 0 && !showFetchingDataLoader">
                            <table class="table table-list shadow-complete">
                                <thead>
                                <tr>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                    </th>
                                    <th class="text-center">
                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                    </th>
                                </tr>
                                </thead>
                                <tbody *ngIf="bulkStudentData && bulkStudentData.length > 0">
                                <tr *ngFor="let bulkStudent of bulkStudentData; let i = index">
                                    <td>{{i + 1}}.</td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].name_message}"
                                               [(ngModel)]="bulkStudent.name"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].name_message">{{bulkStudent['errors'].name_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="text"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].email_message || bulkStudent['errors'].email_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.email"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].email_message">{{bulkStudent['errors'].email_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].email_duplicate_message">{{bulkStudent['errors'].email_duplicate_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <input class="form-control mt-1" type="number"
                                               [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && (bulkStudent['errors'].contact_message || bulkStudent['errors'].contact_duplicate_message)}"
                                               [(ngModel)]="bulkStudent.contact"/>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].contact_message">{{bulkStudent['errors'].contact_message}}</span>
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].contact_duplicate_message">{{bulkStudent['errors'].contact_duplicate_message}}</span>
                                        </small>
                                    </td>
                                    <td>
                                        <select class="form-control mt-1"
                                                [ngClass]="{'mt-30px': bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].course_message}"
                                                [(ngModel)]="bulkStudent.course_id">
                                            <option value="">Select Course</option>
                                            <option [value]="course.id"
                                                    *ngFor="let course of courses">{{course?.name}}</option>
                                        </select>
                                        <small class="text-danger">
                                            <span *ngIf="bulkStudent && bulkStudent['errors'] && bulkStudent['errors'].course_message">{{bulkStudent['errors'].course_message}}</span>
                                        </small>
                                    </td>
                                    <td class="text-center">
                                        <i class="fas fa-trash text-danger cursor-pointer"
                                           (click)="removeStudent(i)"></i>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="transactionData">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         *ngIf="transactionData && transactionData['transactions'] && transactionData['transactions'].length > 0">
                        <table class="table table-list">
                            <thead>
                            <tr>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Transaction No.</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Amount (in Rs.)</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Fee Type</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Date Time</h6>
                                </th>
                                <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                </th>
                            </tr>
                            </thead>
                            <tbody *ngIf="transactionData && transactionData['transactions'] && transactionData['transactions'].length > 0; else noDataToShowWeb">
                            <ng-container *ngFor="let transaction of transactionData['transactions']; let i = index">
                                <tr *ngIf="transaction['rmk'] !== 'IN_PROGRESS'">
                                    <td class="text-center">{{i + 1}}.</td>
                                    <td class="text-center">
                                        <span class="cursor-pointer text-primary"
                                              *ngIf="transaction['transaction_no'] && transaction['rmk'] === 'SUCCESS'"
                                              (click)="goToTransactionReceipt(transaction['transaction_no'])">{{transaction?.transaction_no}}</span>
                                        <span *ngIf="transaction['transaction_no'] && transaction['rmk'] !== 'SUCCESS'">{{transaction?.transaction_no}}</span>
                                    </td>
                                    <td class="text-center">{{transaction?.amount}}</td>
                                    <td class="text-center">
                                        <span *ngIf="transaction['fee_type'] === 'REGISTRATION_FEE'">Registration Fee</span>
                                        <span *ngIf="transaction['fee_type'] === 'COURSE_FEE'">Course Fee</span>
                                        <span *ngIf="transaction['fee_type'] === 'TOTAL_FEE'">Total Fee</span>
                                    </td>
                                    <td class="text-center">{{transaction?.tet}}</td>
                                    <td class="text-center text-capitalize">
                                        <span *ngIf="transaction['rmk'] === 'SUCCESS'">Success</span>
                                        <span *ngIf="transaction['rmk'] === 'FAILURE'">Failure</span>
                                        <span *ngIf="transaction['rmk'] === 'PENDING'">Pending</span>
                                        <span *ngIf="transaction['rmk'] === 'ABORT'">Abort</span>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                            <ng-template #noDataToShowWeb>
                                <tbody>
                                <tr>
                                    <td colspan="6" class="text-center">No data to show</td>
                                </tr>
                                </tbody>
                            </ng-template>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="updateStatus()"
                        *ngIf="selectedStudentData" [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitQuickLead()" *ngIf="quickLeadAdd"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
                <button class="btn btn-danger pull-left mt-1 mb-1" (click)="newUpload()"
                        *ngIf="bulkUploadAdd && bulkStudentData && bulkStudentData.length > 0">
                    New Upload
                </button>
                <button class="btn btn-primary pull-right mt-1 mb-1" (click)="submitBulkUpload()" *ngIf="bulkUploadAdd"
                        [disabled]="submitting">
                    <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>
                    Submit
                </button>
            </div>
        </div>
    </ng-template>
    <div class="content-body">
        <section id="students">
            <div class="row mb-2">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Students</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk Mail" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-envelope color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk WhatsApp" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fab fa-whatsapp color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Bulk SMS" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-sms color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Bulk Upload" (click)="openBulkUploadModal(content)"
                            *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-cloud-upload-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Download Sample" *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1"
                            (click)="downloadSample()">
                        <i class="fas fa-cloud-download-alt color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Add Quick Lead" (click)="openQuickLeadModal(content)"
                            *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-plus-circle color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Assign Lead" (click)="openAssignmentModal(content)"
                            *ngIf="studentChecked && user && ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-tag color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-secondary pull-right ml-6px" rippleEffect
                            title="Assign Lead"
                            *ngIf="!studentChecked && user && ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-user-tag color-white f_20 cursor-pointer"></i>
                    </button>
                    <button type="button" class="btn btn-icon btn-primary pull-right ml-6px" rippleEffect
                            title="Search" (click)="showSearch()"
                            *ngIf="user && ['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR', 'COUNSELLOR', 'UNIVERSITY'].indexOf(user['user_type']) !== -1">
                        <i class="fas fa-search color-white f_20 cursor-pointer"></i>
                    </button>
                </div>
            </div>
            <div class="card" *ngIf="openSearch">
                <div class="card-body">
                    <form class="row text-center" novalidate [formGroup]="searchForm">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"/>
                            <small>Name</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Application Id"
                                   formControlName="username"/>
                            <small>Application Id</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Email" formControlName="email"/>
                            <small>Email</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <input type="text" class="form-control" placeholder="Phone" formControlName="contact"/>
                            <small>Phone</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university.id"
                                        *ngFor="let university of searchUniversities; let i = index">{{university?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university['university'].id"
                                        *ngFor="let university of headCounsellorUniversities; let i = index">{{university['university']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="university['university'].id"
                                        *ngFor="let university of counsellorUniversities; let i = index">{{university['university']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="universityId"
                                    (change)="getUniversityPrograms($event)"
                                    *ngIf="user && ['UNIVERSITY'].indexOf(user['user_type']) !== -1">
                                <option value="">University</option>
                                <option [value]="user['userId']">{{user?.name}}</option>
                            </select>
                            <small>University</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="programId"
                                    (change)="getProgramCourses($event)">
                                <option value="">Programs</option>
                                <option [value]="program.id"
                                        *ngFor="let program of filterPrograms">{{program?.name}}</option>
                            </select>
                            <small>Program</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="courseId"
                                    (change)="getCourseBranches($event)">
                                <option value="">Course</option>
                                <option [value]="course.id"
                                        *ngFor="let course of filterCourses">{{course?.name}}</option>
                            </select>
                            <small>Course</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <select class="form-control" formControlName="branchId">
                                <option value="">Branch</option>
                                <option [value]="branch['branch'].id"
                                        *ngFor="let branch of filterBranches">{{branch?.branch?.nam}}</option>
                            </select>
                            <small>Branch</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="adminId"
                                    (change)="getHeadCounsellorsForSearch($event)"
                                    *ngIf="user && ['SUPER_ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="admin.id" *ngFor="let admin of searchAdmins">{{admin?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    (change)="getHeadCounsellorsForSearch($event)"
                                    *ngIf="user && ['ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="user.userId">{{user?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="headCounsellorDetailAdmin.id">{{headCounsellorDetailAdmin?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="adminId"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Admin</option>
                                <option [value]="counsellorAdmin.id">{{counsellorAdmin?.name}}</option>
                            </select>
                            <small>Admin</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="headCounsellor['head_counsellor'].id"
                                        *ngFor="let headCounsellor of searchHeadCounsellors">{{headCounsellor['head_counsellor']?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="user.userId">{{user?.name}}</option>
                            </select>
                            <select class="form-control" formControlName="headCounsellorId"
                                    (change)="getCounsellorsForSearch($event)"
                                    *ngIf="user && ['COUNSELLOR'].indexOf(user['user_type']) !== -1">
                                <option value="">Head Counsellor</option>
                                <option [value]="counsellorHeadCounsellor.id">{{counsellorHeadCounsellor?.name}}</option>
                            </select>
                            <small>Head Counsellor</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1"
                             *ngIf="user && ['SUPER_ADMIN', 'ADMIN',  'HEAD_COUNSELLOR', 'COUNSELLOR'].indexOf(user['user_type']) !== -1">
                            <select class="form-control" formControlName="counsellorId">
                                <option value="">Counsellor</option>
                                <option [value]="counsellor['counsellor'].id"
                                        *ngFor="let counsellor of searchCounsellors">{{counsellor['counsellor']?.name}}</option>
                            </select>
                            <small>Counsellor</small>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 mb-1">
                            <button class="btn btn-primary btn-block" (click)="search()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill" [(activeId)]="active">
                        <li ngbNavItem [ngbNavItem]="i" *ngFor="let tab of tabs; let i = index">
                            <a ngbNavLink (click)="getTabData(tab, i)">{{tab?.name}}</a>
                            <ng-template ngbNavContent>
                                <div class="card-body">
                                    <div class="row overflow-x-scroll overflow-table">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <table class="table table-list text-center shadow-complete">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">#</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Application Id</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Phone</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                                    </th>

                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Payment Status</h6>
                                                    </th>
                                                    <th class="text-center">
                                                        <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody *ngIf="tab && tab['data'] && tab['data'].length > 0; else noDataToShowWeb">
                                                <tr *ngFor="let data of tab['data']; let i = index">
                                                    <td class="text-center">
                                                        <input class="form-check-input mt--5px ml--8px" type="checkbox"
                                                               [(ngModel)]="data['isChecked']"
                                                               [ngModelOptions]="{standalone: true}"
                                                               (change)="checkStudent()"/>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                            .</h6>
                                                        <!--<h6 class="mt-1">{{i + 1}}.</h6>-->
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.name}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.username}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.email}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.phone}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="data && !data.course && data['register_course']">{{data?.register_course}}</h6>
                                                        <h6 class="mt-1"
                                                            *ngIf="data && data.course">{{data?.course}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer badge-primary"
                                                            *ngIf="['UNIVERSITY'].indexOf(user['user_type']) !== -1">{{data?.status}}</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer"
                                                            *ngIf="['UNIVERSITY'].indexOf(user['user_type']) === -1"
                                                            [ngClass]="{'badge-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['COUNSELLOR'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))))),
                                                            'badge-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))}"
                                                            (click)="changeStatusModal(content, data)">{{data?.status}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1">{{data?.created_at}}</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <h6 class="mt-1"
                                                            *ngIf="!data || !data['transactions'] || data['transactions'].length === 0">
                                                            -</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer"
                                                            [ngClass]="{'badge-success': data['transactions'][0].rmk === 'SUCCESS', 'badge-danger': data['transactions'][0].rmk === 'FAILED'}"
                                                            *ngIf="data && data['transactions'] && data['transactions'].length > 0 && data['transactions'][0].fee_type === 'REGISTRATION_FEE'"
                                                            (click)="changeTransactionModel(content, data)">
                                                            Registration Fee</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer"
                                                            [ngClass]="{'badge-success': data['transactions'][0].rmk === 'SUCCESS', 'badge-danger': data['transactions'][0].rmk === 'FAILED'}"
                                                            *ngIf="data && data['transactions'] && data['transactions'].length > 0 && data['transactions'][0].fee_type === 'COURSE_FEE'"
                                                            (click)="changeTransactionModel(content, data)">
                                                            Course Fee</h6>
                                                        <h6 class="mt-1 badge badge-glow cursor-pointer"
                                                            [ngClass]="{'badge-success': data['transactions'][0].rmk === 'SUCCESS', 'badge-danger': data['transactions'][0].rmk === 'FAILED'}"
                                                            *ngIf="data && data['transactions'] && data['transactions'].length > 0 && data['transactions'][0].fee_type === 'TOTAL_FEE'"
                                                            (click)="changeTransactionModel(content, data)">
                                                            Total Fee</h6>
                                                    </td>
                                                    <td class="text-center">
                                                        <i aria-hidden="true"
                                                           class="fas fa-user-cog f_20 cursor-pointer mt-1 mr-8px"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['COUNSELLOR'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))}"
                                                           title="Perform Actions" (click)="detail(data)"></i>
                                                        <i aria-hidden="true"
                                                           class="fab fa-artstation f_20 cursor-pointer font-weight-light mt-1"
                                                           *ngIf="['COUNSELLOR'].indexOf(user['user_type']) !== -1"
                                                           [ngClass]="{'text-primary': (data &&
                                                           ((data['added_by'] === user['userId'] && (['COUNSELLOR'].indexOf(user['user_type']) !== -1)) || ((data['added_by'] !== user['userId'] &&
                                                           (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))))),
                                                           'text-danger': (data && data['added_by'] === user['userId'] && (['SUPER_ADMIN', 'ADMIN', 'HEAD_COUNSELLOR'].indexOf(user['user_type']) !== -1))}"
                                                           (click)="navigateToUrl('admission/' + data.id)"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <ng-template #noDataToShowWeb>
                                                    <tbody>
                                                    <tr>
                                                        <td colspan="9" class="text-center">
                                                            <h6>No data to show</h6>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </ng-template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row pagination-table mt-2">
                                        <ngb-pagination class="pagination-primary"
                                                        [collectionSize]="studentPagination.total"
                                                        [(page)]="studentPagination.page" [boundaryLinks]="true"
                                                        [pageSize]="studentPagination.perpage" [maxSize]="5"
                                                        (pageChange)="onStudentPageChange($event)">
                                            <ng-template ngbPaginationPrevious
                                            ><span [data-feather]="'chevron-left'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span>
                                            </ng-template>
                                            <ng-template ngbPaginationNext
                                            ><span [data-feather]="'chevron-right'" [size]="18"
                                                   [class]="'font-weight-bolder'"></span
                                            ></ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navFilled" class="mt-2"></div>
                </div>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="detail-student"
            overlayClass="modal-backdrop">
        <app-detail-student [data]="studentDetail" (valueSubmitted)="getData($event)"></app-detail-student>
    </core-sidebar>
</div>
