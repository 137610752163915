import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {Location} from '@angular/common';
import {UniversityCourseCurriculumService} from '../../shared/services/university-course-curriculum.service';
import {AlertService} from '../../shared/services/alert.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-course-curriculum',
    templateUrl: './course-curriculum.component.html',
    styleUrls: ['./course-curriculum.component.scss']
})

export class CourseCurriculumComponent implements OnInit {
    showSearch = false;
    addCourseCurriculumForm: FormGroup;
    onFileChanged: any;
    submitted: any = false;
    submitting: any = false;
    fileFeeStructure: any;
    universityCourseCurriculumData: any;
    pcbMappingId: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private universityCourseCurriculumService: UniversityCourseCurriculumService,
                private _location: Location,
                public alertService: AlertService,
                public activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.pcbMappingId = this.activatedRoute.snapshot.params['id'];
        this.addCourseCurriculumForm = this.fb.group({
            'type': ['', Validators.required],
            'duration': ['', Validators.required],
            'registration_amount': ['', Validators.required],
            'course_fee': ['', Validators.required],
        });
        this.getCourseCurriculum();
    }

    getCourseCurriculum() {
        this.submitting = true;
        this.universityCourseCurriculumService.detailBYPCBId(this.pcbMappingId).subscribe(data => {
            this.universityCourseCurriculumData = data;
            this.submitting = false;
            this.patchCourseCurriculumForm();
        }, error => {
            this.submitting = false;
        });
    }

    patchCourseCurriculumForm() {
        this.addCourseCurriculumForm.patchValue({
            'type': (this.universityCourseCurriculumData && this.universityCourseCurriculumData.type) ? this.universityCourseCurriculumData.type : '',
            'duration': (this.universityCourseCurriculumData && this.universityCourseCurriculumData.duration) ? this.universityCourseCurriculumData.duration : '',
            'registration_amount': (this.universityCourseCurriculumData && this.universityCourseCurriculumData.registration_amount) ? this.universityCourseCurriculumData.registration_amount : '',
            'course_fee': (this.universityCourseCurriculumData && this.universityCourseCurriculumData.course_fee) ? this.universityCourseCurriculumData.course_fee : ''
        });
        if (this.universityCourseCurriculumData && this.universityCourseCurriculumData.fee_structure) {
            this.fileFeeStructure = (this.universityCourseCurriculumData && this.universityCourseCurriculumData.fee_structure) ? this.universityCourseCurriculumData.fee_structure : {};
        }
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.universityCourseCurriculumService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'UNIVERSITY_FEE_STRUCTURE') {
                this.fileFeeStructure = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    back() {
        this._location.back();
    }

    submit() {
        this.submitted = true;
        if (!this.addCourseCurriculumForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'pcb_mapping_id': this.pcbMappingId,
                'type': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().type) ? this.addCourseCurriculumForm.getRawValue().type : '',
                'duration': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().duration) ? this.addCourseCurriculumForm.getRawValue().duration : '',
                'registration_amount': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().registration_amount) ? this.addCourseCurriculumForm.getRawValue().registration_amount : '',
                'course_fee': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().course_fee) ? this.addCourseCurriculumForm.getRawValue().course_fee : '',
                'fee_structure': (this.addCourseCurriculumForm.getRawValue() && this.fileFeeStructure) ? this.fileFeeStructure.id : '',
            };
            this.submitting = true;
            this.universityCourseCurriculumService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addCourseCurriculumForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'pcb_mapping_id': this.pcbMappingId,
                'type': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().type) ? this.addCourseCurriculumForm.getRawValue().type : '',
                'duration': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().duration) ? this.addCourseCurriculumForm.getRawValue().duration : '',
                'registration_amount': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().registration_amount) ? this.addCourseCurriculumForm.getRawValue().registration_amount : '',
                'course_fee': (this.addCourseCurriculumForm.getRawValue() && this.addCourseCurriculumForm.getRawValue().course_fee) ? this.addCourseCurriculumForm.getRawValue().course_fee : '',
                'fee_structure': (this.addCourseCurriculumForm.getRawValue() && this.fileFeeStructure) ? this.fileFeeStructure.id : ''
            };
            this.submitting = true;
            this.universityCourseCurriculumService.update(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
            });
        }
    }

    openFeeStrcuture() {
        window.open(this.fileFeeStructure['url'], '_blank');
    }
}
